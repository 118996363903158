import { createSlice } from '@reduxjs/toolkit';

interface State {
  total: number;
  used: number;
}

interface Action {
  payload: State;
}

const initialState: State = {
  total: 0,
  used: 0
};

export const slice = createSlice({
  name: 'usage',
  initialState,
  reducers: {
    setUsage: (state: State, action: Action) => {
      state.total = action.payload.total;
      state.used = action.payload.used;
    }
  },
});

export const { setUsage } = slice.actions;

export default slice.reducer;
