type Colors = {
  [key: string]: string;
}

const RED1 = "#C63232";
const RED2 = "#FFECEF";
const RED3 = "#DC1616";
const RED4 = "#D9534F";
const RED5 = "#8B0000";
const RED6 = "#D04206";
const RED7 = "#BD0000";
const RED8 = "#FBE8EB";
const RED9 = "#EB4076";
const RED10 = "#FFEBF3";
const RED11 = "#FFD3E1";
const RED12 = "#F60163";
const RED13 = "#FFD7D7";
const RED14 = "#C90000";
const YELLOW1 = "#C56B03";
const YELLOW2 = "#FFFAE9";
const YELLOW3 = "#FBF6E5";
const YELLOW4 = "#FFC244";
const YELLOW5 = "#FBC016";
const YELLOW6 = "#D89B00";
const BLUE1 = "#04AAB5";
const BLUE2 = "#EBFEFF";
const BLUE3 = "#C6C8FF";
const BLUE4 = "#0007B6";
const BLUE5 = "#025DCC";
const BLUE6 = "#4472C4";
const BLUE7 = "#0094FF";
const BLUE8 = "#0057FF";
const BLUE9 = "#30D4DE"; // Turquoise Blue
const BLUE10 = "#3E9EE9";
const BLUE11 = "#00BBEB";
const BLUE_LIGHT1 = "#EBF4FF";
const BLUE_LIGHT2 = "#C8FCFF";
export const WHITE = "#FFFFFF";
export const DEFAULT = "#0D2549";
export const DEFAULT_LIGHT = "#0D254999"; // 60%
export const DEFAULT_LIGHT_WITH_CONTRAST = "#0D2549AA"; // 66%
export const DEFAULT_CONTRAST = "#FFFFFFE3";
const UNKNOWN = "#0D25490D";
const GREEN1 = "#EBFFEE";
const GREEN2 = "#4BBF73";
const GREEN3 = "#20C997";
const GREEN4 = "#04B560";
const GREEN5 = "#CAFFDF";
const GREEN6 = "#CEFFB0";
const GREEN7 = "#005A24";
const GREEN8 = "#318100";
const GREEN10 = "#E7FAFB";
const GREEN11 = "#007C23";
const GREEN12 = "#00D121";
export const GREEN13 = "#35C801";
const GREEN14 = "#00A02E";
const GREEN15 = "#3BE1CF";
const GREEN16 = "#8FA800";
const GREEN17 = "#02B573";
const GREEN_LIGHT1 = "#D0FFF9";
const GREEN_LIGHT2 = "#E0FEE8";
const GREEN_LIGHT3 = "#F7FFC8";
const GREEN_DARK1 = "#008877";
const ORANGE1 = "#F0AD4E";
const ORANGE2 = "#FD7E14";
const ORANGE3 = "#B87900";
const MELON1 = "#FFE4D1";
const MELON2 = "#FFF0C8";
const MELON3 = "#FFE5D7";
const MELON4 = "#A93B00";
const PINK1 = "#FFCFCF";
const PURPLE1 = "#F5EBFF";
const PURPLE2 = "#7507E3";
const PURPLE3 = "#7933FE";
const PURPLE4 = "#7933FE24";
const PURPLE5 = "#EADFFF";
const PURPLE6 = "#EBECFF";
const PURPLE7 = "#7535A5";
const PURPLE8 = "#D7C4E4";
export const PURPLE9 = "#4A144B";
const GRAY_LIGHT = "#F7F9FC";
const GRAY = "#ECF4FF";
export const GRAY2 = "#F0F0F0";
export const GRAY3 = "#F5F8FC";
export const GRAY4 = "#7A7976";
export const GRAY5 = "#F3F4F6";
export const GRAY6 = "#495057";
export const GRAY7 = "#E0E0E0";
const GRAY8 = "#82828299";
const GRAY9 = "#C9C9C9";
export const GRAY10 = "#3F3F3F";
export const GRAY11 = "#CFCFD0";
export const GRAY12 = "#E1E1E1";
export const GRAY13 = "#606060";
const BROWN1 = "#E9D4B4";
const BROWN2 = "#663E00";

export const TARGETTED_SCORE: Colors = {
  LOW_BACKGROUND: GREEN_LIGHT2,
  MODERATE_BACKGROUND: BLUE2,
  HIGH_BACKGROUND: PURPLE5,
  LOW_COLOR: GREEN17,
  MODERATE_COLOR: BLUE11,
  HIGH_COLOR: PURPLE3,
  ACADEMIC_BACKGROUND: RED10,
  ACADEMIC_COLOR: RED12,
  SPOKEN_BACKGROUND: GREEN_LIGHT1,
  SPOKEN_COLOR: GREEN_DARK1,
  NEWS_BACKGROUND: MELON3,
  NEWS_COLOR: MELON4,
  FICTION_BACKGROUND: GREEN_LIGHT3,
  FICTION_COLOR: GREEN16,
  POSITIVE_COLOR: GREEN11,
  NEGATIVE_COLOR: RED1,
  NEUTRAL_COLOR: YELLOW6,
  POSITIVE_BACKGROUND: GREEN_LIGHT2,
  NEGATIVE_BACKGROUND: RED2,
  NEUTRAL_BACKGROUND: YELLOW2,
};

export const TARGETTED_BAR_COLORS: Colors = {
  GOOD: BLUE1,
  MEDIUM: ORANGE1,
  BAD: RED4,
  POSITIVE: GREEN4,
  NEGATIVE: RED4,
  NEUTRAL: BLUE1,
  LOW: GREEN17,
  MODERATE: BLUE11,
  HIGH: PURPLE3,
  POSITIVE_BACKGROUND: GREEN_LIGHT2,
  NEGATIVE_BACKGROUND: RED10,
  NEUTRAL_BACKGROUND: BLUE2,
  ACADEMIC_BACKGROUND: RED10,
  ACADEMIC_COLOR: RED12,
  SPOKEN_BACKGROUND: GREEN_LIGHT1,
  SPOKEN_COLOR: GREEN_DARK1,
  NEWS_BACKGROUND: MELON3,
  NEWS_COLOR: MELON4,
  FICTION_BACKGROUND: GREEN_LIGHT3,
  FICTION_COLOR: GREEN16,
};

export const VOLUME_BACKGROUND = PURPLE8;
export const VOLUME_COLOR = PURPLE7;

export const TUTORIAL_BACKGROUND = BLUE7;
export const TUTORIAL_COLOR = "white";
export const TUTORIAL_BORDER_COLOR = BLUE8;

export const TUTORIAL_ACTION_BACKGROUND = PURPLE6;
export const TUTORIAL_ACTION_COLOR = PURPLE3;
export const TUTORIAL_ACTION_BORDER_COLOR = GRAY8;
export const TUTORIAL_ACTION_COLOR_INACTIVE = GRAY8;
export const TUTORIAL_BUTTON_BACKGROUND = YELLOW2;
export const SCROLLBAR_THUMB_COLOR = GRAY8;
export const BUTTON_SEE_DETAILS_BACKGROUND = PURPLE3;
export const BUTTON_PROPERTY_BACKGROUND = PURPLE3;
export const FIND_OUT_MORE_TITLE_COLOR = PURPLE3;
export const BUTTONS_EDIT_MODE_BACKGROUND = DEFAULT;
export const BUTTONS_EDIT_MODE_BUTTONS_BACKGROUND = DEFAULT;
export const FIND_OUT_MORE_ACTION_COLOR = DEFAULT;
export const WORD_BANK_BUTTON_YES_BACKGROUND = YELLOW5;
export const WORD_BANK_BUTTON_NO_BACKGROUND = BLUE9;
export const EXPLANATION_LINK_COLOR = BLUE8;
export const LIST_ITEM_DOT_COLOR = PURPLE3;
export const PROGRESSBAR_COLOR = PURPLE3;

export const RESULT_BODY_BACKGROUND = BLUE_LIGHT2;
export const BAR_CHART_IN_HOME = {
  LOW: BLUE1,
  MODERATE: YELLOW4,
  HIGH: RED1,
};

export const WORD_EXPLORER: Colors = {
  POSITIVE: GREEN14,
  POSITIVE_BACKGROUND: GREEN_LIGHT2,
  NEGATIVE: RED1,
  NEGATIVE_BACKGROUND: RED2,
  NEUTRAL: YELLOW1,
  NEUTRAL_BACKGROUND: YELLOW2,
  OTHER: DEFAULT,
  OTHER_BACKGROUND: UNKNOWN,
  BUTTON_COLOR: PURPLE3,
  BUTTON_BACKGROUND: PURPLE4,
};

export const ACADEMIC = GRAY_LIGHT;
export const POSITIVE = GREEN11;
export const POSITIVE_BACKGROUND = GREEN_LIGHT2;
export const SUGGESTION_BUTTON_BACKGROUND = PURPLE3;
export const FILTER_ALL_BACKGROUND = GRAY;

export const PAGE_BACKGROUND = GRAY_LIGHT;
export const SUBTAB_TIPS_BACKGROUND = PURPLE5;
export const SUBTAB_TIPS_COLOR = PURPLE3;
export const CHECKBOX_TICK_BACKGROUND = GREEN12;
export const CHECKBOX_TICK_PRIVACY_BACKGROUND = PURPLE3;
export const CHECKBOX_TICK_EMAIL_BACKGROUND = BLUE6;
export const BUTTON_SEE_TEXT_BACKGROUND = RED9;
export const SHOW_MORE_TEXT_COLOR = PURPLE3;
export const DIFFICULT_SENTECES_TITLE_COLOR = PURPLE3;
export const BUTTON_WORD_CLOUD_MORE_DETAILS_BORDER = PURPLE3;

export const LABEL_WARNING_BACKGROUND = RED2;
export const LABEL_WARNING_COLOR = RED1;
export const DANGER_COLOR = RED1;

export const WORD_CLOUD: Colors = {
  TINY_BACKGROUND: YELLOW2,
  TINY_COLOR: YELLOW1,
  SMALL_BACKGROUND: GREEN5,
  SMALL_COLOR: GREEN7,
  MEDIUM_BACKGROUND: BLUE_LIGHT2,
  MEDIUM_COLOR: BLUE1,
  BIG_BACKGROUND: RED11,
  BIG_COLOR: RED9,
  VERY_BIG_BACKGROUND: PURPLE5,
  VERY_BIG_COLOR: PURPLE3,
};

export const DIFFICULT_SENTECES: Colors = {
  LONG_BACKGROUND: MELON2,
  LONG_COLOR: ORANGE3,
  VERY_LONG_BACKGROUND: GREEN6,
  VERY_LONG_COLOR: GREEN8,
  PASSIVE_BACKGROUND: BLUE3,
  PASSIVE_COLOR: BLUE4,
  LEFT_HEAVY_BACKGROUND: GREEN5,
  LEFT_HEAVY_COLOR: GREEN7,
  NON_FINITE_BACKGROUND: PURPLE1,
  NON_FINITE_COLOR: PURPLE2,
  MORE_THAN_1_BACKGROUND: RED2,
  MORE_THAN_1_COLOR: RED1,
};

export const RESULTS_SQUARES: Colors = {
  WORD_COMPLEXITY_BACKGROUND: MELON2,
  WORD_COMPLEXITY_COLOR: ORANGE3,
  PROCESSING_BACKGROUND: GREEN6,
  PROCESSING_COLOR: GREEN8,
  ENGAGEMENT_BACKGROUND: BLUE3,
  ENGAGEMENT_COLOR: BLUE4,
  SENTIMENT_BACKGROUND: GREEN5,
  SENTIMENT_COLOR: GREEN7,
  DIRECTNESS_BACKGROUND: PURPLE1,
  DIRECTNESS_COLOR: PURPLE2,
};

export const DIFFICULT_WORDS: Colors = {
  RARE_BACKGROUND_ACTIVE: RED8,
  RARE_BACKGROUND: RED2,
  RARE_COLOR: RED1,
  LESS_COMMONS_BACKGROUND_ACTIVE: YELLOW3,
  LESS_COMMONS_BACKGROUND: YELLOW2,
  LESS_COMMONS_COLOR: YELLOW1,
  COMMONS_BACKGROUND_ACTIVE: GREEN10,
  COMMONS_BACKGROUND: BLUE2,
  COMMONS_COLOR: BLUE1,
  ADVANCED_BACKGROUND: RED2,
  ADVANCED_COLOR: RED1,
  LONG_BACKGROUND: YELLOW2,
  LONG_COLOR: YELLOW1,
  DIFFICULT_WORDS_BACKGROUND: GRAY,
  DIFFICULT_WORDS_COLOR: DEFAULT,
};

export const LEVELS: Readonly<Colors> = {
  level1: GRAY9,
  level2: RED9,
  level3: YELLOW5,
  level4: GREEN15,
  level5: BLUE10,
  level6: PURPLE3,
};

export const LEVELS_ENGLISH: Readonly<Colors> = {
  A1: "#64EBB9",
  A2: "#95B935",
  B1: "#F9F871",
  B2: YELLOW4,
  C1: "#FF7F51",
  C2: RED1,
};

export const levelColors: Readonly<Colors> = {
  high: RED1,
  moderate: YELLOW1,
  low: BLUE1,
};

export const levelBackgroundColors: Readonly<Colors> = {
  high: RED2,
  moderate: YELLOW2,
  low: BLUE2,
};

export const cEFRChartBackgroundColors: Readonly<Colors> = {
  A1: BLUE2,
  A2: BLUE2,
  B1: YELLOW2,
  B2: YELLOW2,
  C1: RED2,
  C2: RED2,
  unclassified: UNKNOWN,
  urls: UNKNOWN,
  emails: UNKNOWN,
  numbers: UNKNOWN,
  abbreviations: UNKNOWN,
  proper_nouns: UNKNOWN,
  hashtags: UNKNOWN,
};

export const cEFRChartColors: Readonly<Colors> = {
  A1: BLUE1,
  A2: BLUE1,
  B1: YELLOW1,
  B2: YELLOW1,
  C1: RED3,
  C2: RED3,
  unclassified: DEFAULT,
  urls: DEFAULT,
  emails: DEFAULT,
  numbers: DEFAULT,
  abbreviations: DEFAULT,
  proper_nouns: DEFAULT,
  hashtags: DEFAULT,
};

export const cEFRBackgroundColors: Readonly<Colors> = {
  A1: BLUE2,
  A2: BLUE2,
  B1: YELLOW2,
  B2: YELLOW2,
  C1: RED2,
  C2: RED2,
  user_keys: BLUE_LIGHT1,
  unclassified: UNKNOWN,
  urls: UNKNOWN,
  emails: UNKNOWN,
  numbers: UNKNOWN,
  abbreviations: UNKNOWN,
  proper_nouns: UNKNOWN,
  hashtags: UNKNOWN,
};

export const cEFRColors: Readonly<Colors> = {
  A1: GREEN3,
  A2: GREEN2,
  B1: ORANGE1,
  B2: ORANGE2,
  C1: RED4,
  C2: RED5,
  user_keys: BLUE5,
  unclassified: DEFAULT,
  urls: DEFAULT,
  emails: DEFAULT,
  numbers: DEFAULT,
  abbreviations: DEFAULT,
  proper_nouns: DEFAULT,
  hashtags: DEFAULT,
};

export const rangeChartBackgroundColors: Readonly<Colors> = {
  "0K-2K": GREEN1,
  "2K-4K": BLUE2,
  "4K-6K": YELLOW2,
  "6K-8K": MELON1,
  "8K-10K": RED2,
  "10K-25K": PINK1,
  unclassified: UNKNOWN,
  urls: UNKNOWN,
  emails: UNKNOWN,
  numbers: UNKNOWN,
  abbreviations: UNKNOWN,
  proper_nouns: UNKNOWN,
  mentions: UNKNOWN,
  foreigns: UNKNOWN,
};

export const rangeChartColors: Readonly<Colors> = {
  "0K-2K": GREEN4,
  "2K-4K": BLUE1,
  "4K-6K": YELLOW1,
  "6K-8K": RED6,
  "8K-10K": RED1,
  "10K-25K": RED7,
  unclassified: DEFAULT,
  urls: DEFAULT,
  emails: DEFAULT,
  numbers: DEFAULT,
  abbreviations: DEFAULT,
  proper_nouns: DEFAULT,
  hashtags: DEFAULT,
  mentions: DEFAULT,
  foreigns: DEFAULT,
};

export const rangeBackgroundColors: Readonly<Colors> = {
  "0K-2K": GREEN1,
  "2K-4K": BLUE2,
  "4K-6K": YELLOW2,
  "6K-8K": MELON1,
  "8K-10K": RED2,
  "10K-25K": PINK1,
  user_keys: BLUE_LIGHT1,
  unclassified: UNKNOWN,
  urls: UNKNOWN,
  emails: UNKNOWN,
  numbers: UNKNOWN,
  abbreviations: UNKNOWN,
  proper_nouns: UNKNOWN,
  hashtags: UNKNOWN,
  mentions: UNKNOWN,
  foreigns: UNKNOWN,
};

export const rangeColors: Readonly<Colors> = {
  "0K-2K": GREEN4,
  "2K-4K": BLUE1,
  "4K-6K": YELLOW1,
  "6K-8K": RED6,
  "8K-10K": RED1,
  "10K-25K": RED7,
  user_keys: BLUE5,
  unclassified: DEFAULT,
  urls: DEFAULT,
  emails: DEFAULT,
  numbers: DEFAULT,
  abbreviations: DEFAULT,
  proper_nouns: DEFAULT,
  hashtags: DEFAULT,
  mentions: DEFAULT,
  foreigns: DEFAULT,
};

export const difficultBackgroundColors: Readonly<Colors> = {
  advanced: RED13,
  difficult: RED13,
  long: MELON2,
  normal: UNKNOWN,
};

export const difficultColors: Readonly<Colors> = {
  advanced: RED14,
  difficult: RED14,
  long: ORANGE3,
  normal: DEFAULT,
};

export const largestBackgroundColors: Readonly<Colors> = {
  long_sentence: MELON2,
  left_heavy: GREEN5,
  non_finite_phrase: PURPLE1,
  passive: BLUE3,
  very_long_sentence: GREEN6,
  several_issues: RED2,
  short: UNKNOWN,
};

export const largestColors: Readonly<Colors> = {
  long_sentence: ORANGE3,
  left_heavy: GREEN7,
  non_finite_phrase: PURPLE2,
  passive: BLUE4,
  very_long_sentence: GREEN8,
  several_issues: RED1,
  short: DEFAULT,
};

export const otherCategoriesBackgroundColors: Readonly<Colors> = {
  "booster-words": MELON2,
  "empathy-phrases": MELON3,
  "exclusion-words": PURPLE1,
  "personal-pronouns-plural": BLUE3,
  "personal-pronouns-singular": GREEN6,
  "hedge-words": GREEN_LIGHT1,
  questions: BLUE_LIGHT1,
  other: UNKNOWN,
  jargon: RED10,
  "positive-words": GREEN1,
  "negative-words": RED13,
  "second-personal-pronouns": GREEN_LIGHT3,
  sensory: GREEN5,
  contractions: YELLOW2,
  verbs_from_nouns: BROWN1,
};

export const otherCategoriesColors: Readonly<Colors> = {
  "booster-words": ORANGE3,
  "empathy-phrases": MELON4,
  "exclusion-words": PURPLE2,
  "personal-pronouns-plural": BLUE4,
  "personal-pronouns-singular": GREEN8,
  "hedge-words": GREEN_DARK1,
  questions: BLUE5,
  other: DEFAULT,
  jargon: RED12,
  "positive-words": "#10C02B",
  "negative-words": RED14,
  "second-personal-pronouns": GREEN16,
  sensory: GREEN7,
  contractions: YELLOW1,
  verbs_from_nouns: BROWN2,
};

export const negatorsBackgroundColors: Readonly<Colors> = {
  simple: GREEN_LIGHT3,
  multiple: RED10,
};

export const negatorsColors: Readonly<Colors> = {
  simple: GREEN16,
  multiple: RED12,
};

export const partsBackgroundColors: Readonly<Colors> = {
  adjective: GREEN_LIGHT3,
  preposition: GREEN1,
  adverb: GREEN6,
  "auxiliary verb": GREEN_LIGHT1,
  "coordinating conjunction": BLUE3,
  determiner: BLUE_LIGHT1,
  interjection: PURPLE1,
  noun: MELON3,
  numeral: "#FFF9EA",
  particle: RED13,
  pronoun: MELON2,
  "proper noun": BROWN1,
  "subordinating conjunction": RED10,
  verb: GREEN5,
  other: "#6C757D0D",
};

export const partsColors: Readonly<Colors> = {
  adjective: GREEN16,
  preposition: "#10C02B",
  adverb: GREEN8,
  "auxiliary verb": GREEN_DARK1,
  "coordinating conjunction": BLUE4,
  determiner: BLUE5,
  interjection: PURPLE2,
  noun: MELON4,
  numeral: "#BC8B0D",
  particle: RED14,
  pronoun: ORANGE3,
  "proper noun": BROWN2,
  "subordinating conjunction": RED12,
  verb: GREEN7,
  other: "#6C757D",
};

export const partsClass: Readonly<Colors> = {
  adjective: "adjective",
  preposition: "preposition",
  adverb: "adverb",
  "auxiliary verb": "auxverb",
  "coordinating conjunction": "coordinating-conjunction",
  determiner: "determiner",
  interjection: "interjection",
  noun: "noun",
  numeral: "numeral",
  particle: "particle",
  pronoun: "pronoun",
  "proper noun": "propernoun",
  "subordinating conjunction": "subordinating-conjunction",
  verb: "verb",
  other: "other",
  all: "all",
};

export const searchBackgroundColors: Readonly<Colors> = {
  word: PURPLE4,
  synonyms: GREEN5,
  antonyms: RED10,
  lemma: BLUE_LIGHT1,
};

export const searchColors: Readonly<Colors> = {
  word: PURPLE3,
  synonyms: GREEN7,
  antonyms: RED12,
  lemma: BLUE5,
};

export const cEFRClass: Readonly<Colors> = {
  A1: "A1",
  A2: "A2",
  B1: "B1",
  B2: "B2",
  C1: "C1",
  C2: "C2",
  others: "others",
  unclassified: "unclassified",
  urls: "urls",
  emails: "emails",
  numbers: "numbers",
  abbreviations: "abbreviations",
  proper_nouns: "proper_nouns",
  hashtags: "hashtags",
  mentions: "mentions",
  foreigns: "foreigns",
  user_keys: "user_keys",
  all: "all",
};

export const cEFRTIP: Readonly<Colors> = {
  A1: "A1",
  A2: "A2",
  B1: "B1",
  B2: "B2",
  C1: "C1",
  C2: "C2",
  others: "Others",
  unclassified: "Unclassified",
  urls: "Urls",
  emails: "Emails",
  numbers: "Numbers",
  abbreviations: "Abbreviations",
  proper_nouns: "Names",
  hashtags: "Hashtags",
  mentions: "Mentions",
  foreigns: "Foreigns",
  user_keys: "Key Terms",
  all: "all",
};

export const rangeClass: Readonly<Colors> = {
  "0K-2K": "r02K",
  "2K-4K": "r24K",
  "4K-6K": "r46K",
  "6K-8K": "r68K",
  "8K-10K": "r810K",
  "10K-25K": "r1025K",
  others: "others",
  unclassified: "unclassified",
  urls: "urls",
  emails: "emails",
  numbers: "numbers",
  abbreviations: "abbreviations",
  proper_nouns: "proper_nouns",
  hashtags: "hashtags",
  mentions: "mentions",
  foreigns: "foreigns",
  user_keys: "user_keys",
  all: "all",
};

export const rangeTIP: Readonly<Colors> = {
  "0K-2K": "Most common words (0-2k)",
  "2K-4K": "Commonly used words (2k-4k)",
  "4K-6K": "Often used words (4k-6k)",
  "6K-8K": "Less common words (6k-8k)",
  "8K-10K": "Rarely used words (8k-10k)",
  "10K-25K": "Very rarely used words (10k-25k)",
  others: "Others",
  unclassified: "Unclassified",
  urls: "Urls",
  emails: "Emails",
  numbers: "Numbers",
  abbreviations: "Abbreviations",
  proper_nouns: "Names",
  hashtags: "Hashtags",
  mentions: "Mentions",
  foreigns: "Foreigns",
  user_keys: "Key Terms",
  all: "all",
};

export const BRAIN_PAIN_BACKGROUND_COLORS: Readonly<Colors> = {
  LOW: BLUE2,
  MODERATE: YELLOW2,
  HIGH: RED2,
};

export const BRAIN_PAIN_COLORS: Readonly<Colors> = {
  LOW: BLUE1,
  MODERATE: ORANGE1,
  HIGH: RED4,
};

export const BRAIN_PAIN_DIFFICULTY_RATING: Readonly<Colors> = {
  "1": GRAY9,
  "2": RED9,
  "3": YELLOW5,
  "4": GREEN15,
  "5": BLUE10,
  "6": PURPLE3,
};
