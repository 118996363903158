import type { LocalStorageFunctions, IntUserResponse, LocalCompareDocument } from './types';
import type { ComparableDocument } from 'pages/MyDocuments/types';

const COMPARE_DOCUMENT: string = 'compare-document';
const USER: string = 'tigim-user';

const getUser = (fn: LocalStorageFunctions) => (): IntUserResponse | undefined =>
  fn.readObject(USER);

const getLocaStorageCompareDocument = (fn: LocalStorageFunctions) => (): ComparableDocument | undefined => {
  const user = getUser(fn)();
  if (!user) return undefined;
  const choice = fn.readObject(COMPARE_DOCUMENT);
  if (!choice?.[user.user.email]) return undefined;
  return choice[user.user.email];
};

const setLocaStorageCompareDocument = (fn: LocalStorageFunctions) => (data: ComparableDocument | undefined) => {
  const user = getUser(fn)();
  if (!user) return undefined;
  let choice = fn.readObject(COMPARE_DOCUMENT);
  if (!choice) {
    choice = {};
  }
  choice[user.user.email] = data;
  fn.writeObject(COMPARE_DOCUMENT, choice);
};

const delLocaStorageCompareDocument = (fn: LocalStorageFunctions) => () => {
  const user = getUser(fn)();
  if (!user) return undefined;
  const choice = fn.readObject(COMPARE_DOCUMENT);
  if (!choice?.[user.user.email]) return undefined;
  delete choice[user.user.email];
  fn.writeObject(COMPARE_DOCUMENT, choice);
};

const compareDocument = (fn: LocalStorageFunctions): LocalCompareDocument => ({
  getCompareDocument: getLocaStorageCompareDocument(fn),
  setCompareDocument: setLocaStorageCompareDocument(fn),
  delCompareDocument: delLocaStorageCompareDocument(fn)
});

export default compareDocument;
