import type { LocalNotEditableWarning, LocalStorageFunctions } from './types';

const NOT_EDITABLE_WARNING: string = 'not-editable-warning';

const getLocaStorageNotEditableWarning = (fn: LocalStorageFunctions) => (): boolean => !!fn.readObject(NOT_EDITABLE_WARNING);
const setLocaStorageNotEditableWarning = (fn: LocalStorageFunctions) => () => fn.writeObject(NOT_EDITABLE_WARNING, true);

const notEditableWarning = (fn: LocalStorageFunctions): LocalNotEditableWarning => ({
  getLocaStorageNotEditableWarning: getLocaStorageNotEditableWarning(fn),
  setLocaStorageNotEditableWarning: setLocaStorageNotEditableWarning(fn)
});

export default notEditableWarning;
