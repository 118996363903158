import type {
  IntUser,
  IntUserResponse,
  LocalAuth,
  LocalStorageFunctions
} from './types';

const USER: string = 'tigim-user';
const LOGIN_TIME: string = 'tigim-loging-time';
const LOGIN_LIMIT: number = (86400 * 8 * 1000) - 100000;

const now = () => new Date().getTime();
const setLoginTime = (fn: LocalStorageFunctions) => () => fn.writeObject(LOGIN_TIME, now());
const getLoginTime = (fn: LocalStorageFunctions) => () => fn.readObject(LOGIN_TIME);
const delUser = (fn: LocalStorageFunctions) => () => fn.removeObject(USER);
const getUser = (fn: LocalStorageFunctions) => (): IntUserResponse | undefined => {
  const user = fn.readObject(USER);
  if (user === undefined) {
    return undefined;
  }
  const dt = getLoginTime(fn)();
  const newDt = now();
  if (dt === undefined || newDt - dt > LOGIN_LIMIT) {
    delUser(fn)();
    return undefined;
  }
  return user;
};
const setUser = (fn: LocalStorageFunctions) => (user: IntUserResponse) => {
  setLoginTime(fn)();
  return fn.writeObject(USER, user);
};
const setUserUser = (fn: LocalStorageFunctions) => (user: IntUser) => {
  setLoginTime(fn)();
  const userResponse = getUser(fn)();
  if (userResponse !== undefined) {
    userResponse.user = user;
    return fn.writeObject(USER, userResponse);
  }
};
const isPremium = (fn: LocalStorageFunctions) => (): boolean => {
  const user = getUser(fn)();
  if (!user?.user?.credits) return false;
  return user?.user?.credits >= 0;
};
const isLoggedIn = (fn: LocalStorageFunctions) => (): boolean => getUser(fn)() !== undefined;
const hasSubscription = (fn: LocalStorageFunctions) => (): boolean => {
  const user = getUser(fn)();
  return (user === undefined)? false: !!user?.user?.has_subscription;
};
const setHasSubscription = (fn: LocalStorageFunctions) => (value: boolean) => {
  const user = getUser(fn)();
  if (user !== undefined) {
    user.user.has_subscription = value;
  }
  fn.writeObject(USER, user);
};
const getMaxChars = (fn: LocalStorageFunctions) => (): number => {
  const user = getUser(fn)();
  if (user === undefined) return 1500;
  if (user?.user?.limits) return user?.user?.limits.max_chars;
  return (user?.user?.has_subscription)? 0: 1500;
};
const getMaxPDFSize = (fn: LocalStorageFunctions) => (): number => {
  const user = getUser(fn)();
  if (user === undefined) return 1024 * 1024;
  if (user?.user?.limits) return user?.user?.limits.max_file_size * 1024 * 1024;
  return (user?.user?.has_subscription)? 0: (1024 * 1024);
};
const isAdmin = (fn: LocalStorageFunctions) => (): boolean => {
  const user = getUser(fn)();
  return (user === undefined)? false: user?.user?.is_superuser;
};
const isPrepaid = (fn: LocalStorageFunctions) => (): boolean => {
  const user = getUser(fn)();
  return (user === undefined)? false: user?.user?.prepaid;
};
const isTrial = (fn: LocalStorageFunctions) => (): boolean => {
  const user = getUser(fn)();
  return (user === undefined)? false: user?.user?.user_type === 'trial';
};
const isPersonal = (fn: LocalStorageFunctions) => (): boolean => {
  const user = getUser(fn)();
  return (user === undefined)? false: user?.user?.user_type === 'personal';
};
const isBusiness = (fn: LocalStorageFunctions) => (): boolean => {
  const user = getUser(fn)();
  return (user === undefined)? false: user?.user?.user_type === 'business';
};
const hasOnlyLicence = (fn: LocalStorageFunctions) => (): boolean => {
  const user = getUser(fn)();
  return (user === undefined)? false: user?.user?.user_type === 'licenced';
};
const canShare = (fn: LocalStorageFunctions) => (): boolean => isBusiness(fn)() || hasOnlyLicence(fn)();
const hasLicence = (fn: LocalStorageFunctions) => (): boolean => {
  const user = getUser(fn)();
  if (user === undefined) return true;
  return (user?.user?.limits)? true: false;
};
const hasMyDocuments = (fn: LocalStorageFunctions) => (): boolean =>
  hasLicence(fn)() || isTrial(fn)();

const updateCredits = (fn: LocalStorageFunctions) => (credits: number) => {
  const user = getUser(fn)();
  if (user
    && user?.user?.credits !== null
    && user?.user?.credits > 0) {
    user.user.credits = credits;
    setUser(fn)(user);
  }
};
const removeOneCredit = (fn: LocalStorageFunctions) => () => {
  const user = getUser(fn)();
  if (user
    && user?.user?.credits !== null
    && user?.user?.credits > 0) {
    user.user.credits -= 1;
    setUser(fn)(user);
  }
};

const auth = (fn: LocalStorageFunctions): LocalAuth => ({
  getUser: getUser(fn),
  setUser: setUser(fn),
  delUser: delUser(fn),
  setUserUser: setUserUser(fn),
  isLoggedIn: isLoggedIn(fn),
  isPremium: isPremium(fn),
  isAdmin: isAdmin(fn),
  isPrepaid: isPrepaid(fn),
  isTrial: isTrial(fn),
  isPersonal: isPersonal(fn),
  isBusiness: isBusiness(fn),
  hasLicence: hasLicence(fn),
  canShare: canShare(fn),
  hasOnlyLicence: hasOnlyLicence(fn),
  hasSubscription: hasSubscription(fn),
  hasMyDocuments: hasMyDocuments(fn),
  setHasSubscription: setHasSubscription(fn),
  getMaxChars: getMaxChars(fn),
  getMaxPDFSize: getMaxPDFSize(fn),
  removeOneCredit: removeOneCredit(fn),
  updateCredits: updateCredits(fn)
});

export default auth;
