import React from 'react';
import type { TokenResponse } from '@react-oauth/google';

import { useMainContext } from 'routes/MainContextProvider';
import { IntUserResponse } from 'services/local/types';
import catchErrorAndCloseLoading from 'functions/catchErrorAndCloseLoading';
import errorMessage from 'functions/errorMessage';
import ModalSignUpLarge from './ModalSignUpLarge';
import { register as registerSuccess } from 'store/reducers/auth';
import { close as setCloseSignUp } from 'store/reducers/signUp';
import type { GmailSignInData, SignUpData } from 'services/http/types';

const ModalSignUpLargeDispatcher = () => {
  const {
    api,
    local,
    toastr,
    history,
    dispatch,
    scrollToTop,
    openLoading,
    closeLoading,
    signUp
  } = useMainContext();

  const onSignUp = (data: SignUpData) =>
    api.auth.signUp(data).then((response: {data: IntUserResponse}) => {
      toastr.success('', 'user created, please check your email to confirm');
      return Promise.resolve(response.data);
    }).catch((error: unknown) => {
      errorMessage(toastr)(error);
      return Promise.reject(error);
    });

  const onSignUpGmail = (googleUser: TokenResponse): Promise<IntUserResponse> => {
    const id_token = googleUser.access_token;
    const reportId = local.analysis.getLastFullReportId();
    const data: GmailSignInData = {
      token: id_token
    };
    if (reportId) {
      data.report_id = reportId
    }
    return api.auth.gmailSignIn(data);
  };

  const onClose = () => {
    dispatch(setCloseSignUp());
  };

  const onSend = (data: SignUpData) => {
    openLoading('Creating account...');
    const reportId = local.analysis.getLastFullReportId();
    data.report_id = null;
    if (reportId) {
      data.report_id = reportId
    }
    return onSignUp(data).then((response: IntUserResponse) => {
      closeLoading();
      scrollToTop();
      onClose();
      if (reportId) {
        history('/');
      }
      return Promise.resolve(response);
    }).catch(catchErrorAndCloseLoading(closeLoading));
  };

  const onSendGmail = (googleUser: TokenResponse) => {
    openLoading('Creating account...');
    return onSignUpGmail(googleUser).then((response: IntUserResponse) => {
      local.auth.setUser(response);
      dispatch(registerSuccess(response))
      closeLoading();
      scrollToTop();
      if (local.analysis.getLastFullReportId()) {
        history('/documents/my-documents/1');
      }
      return Promise.resolve(response);
    }).catch(catchErrorAndCloseLoading(closeLoading));
  };
  if (!signUp) return null;
  return (
    <ModalSignUpLarge
      onClose={onClose}
      onSend={onSend}
      onSendGmail={onSendGmail} />
  );
};

export default ModalSignUpLargeDispatcher;
