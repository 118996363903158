import type { LocalStorageFunctions, IntUserResponse, LocalCustomerMetricsOrder } from './types';
import { DEFAULT_ITEMS } from 'constants/customerMetricsOrder';

const CUSTOMER_METRICS_ORDER: string = 'customer-metrics-order';
const USER: string = 'tigim-user';

const getUser = (fn: LocalStorageFunctions) => (): IntUserResponse | undefined =>
  fn.readObject(USER);

const getLocaStorageCustomerMetricsOrder = (fn: LocalStorageFunctions) => (): string[] => {
  const user = getUser(fn)();
  if (!user) return DEFAULT_ITEMS;
  const choice = fn.readObject(CUSTOMER_METRICS_ORDER);
  if (!choice?.[user.user.email]) return DEFAULT_ITEMS;
  return choice[user.user.email];
};

const setLocaStorageCustomerMetricsOrder = (fn: LocalStorageFunctions) => (data: string[]) => {
  const user = getUser(fn)();
  if (!user) return;
  let choice = fn.readObject(CUSTOMER_METRICS_ORDER);
  if (!choice) {
    choice = {};
  }
  choice[user.user.email] = data;
  fn.writeObject(CUSTOMER_METRICS_ORDER, choice);
};

const delLocaStorageCustomerMetricsOrder = (fn: LocalStorageFunctions) => () => {
  const user = getUser(fn)();
  if (!user) return;
  const choice = fn.readObject(CUSTOMER_METRICS_ORDER);
  if (!choice?.[user.user.email]) return;
  delete choice[user.user.email];
  fn.writeObject(CUSTOMER_METRICS_ORDER, choice);
};

const customerMetricsOrder = (fn: LocalStorageFunctions): LocalCustomerMetricsOrder => ({
  getLocaStorageCustomerMetricsOrder: getLocaStorageCustomerMetricsOrder(fn),
  setLocaStorageCustomerMetricsOrder: setLocaStorageCustomerMetricsOrder(fn),
  delLocaStorageCustomerMetricsOrder: delLocaStorageCustomerMetricsOrder(fn)
});

export default customerMetricsOrder;
