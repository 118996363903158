import type { LocalStorageFunctions, IntUserResponse, LocalProductSaved } from './types';
import type { AppropriateTextSaved } from 'pages/ECommerceCreate/types';

const PRODUCT_SAVED: string = 'product-saved';
const USER: string = 'tigim-user';

const getUser = (fn: LocalStorageFunctions) => (): IntUserResponse | undefined =>
  fn.readObject(USER);

const getLocaStorageProductSaved = (fn: LocalStorageFunctions) => (): AppropriateTextSaved[] => {
  const user = getUser(fn)();
  if (!user) return [];
  const choice = fn.readObject(PRODUCT_SAVED);
  if (!choice?.[user.user.email]) return [];
  return choice[user.user.email];
};

const setLocaStorageProductSaved = (fn: LocalStorageFunctions) => (data: AppropriateTextSaved[]) => {
  const user = getUser(fn)();
  if (!user) return;
  let choice = fn.readObject(PRODUCT_SAVED);
  if (!choice) {
    choice = {};
  }
  choice[user.user.email] = data;
  fn.writeObject(PRODUCT_SAVED, choice);
};

const delLocaStorageProductSaved = (fn: LocalStorageFunctions) => () => {
  const user = getUser(fn)();
  if (!user) return;
  const choice = fn.readObject(PRODUCT_SAVED);
  if (!choice?.[user.user.email]) return;
  delete choice[user.user.email];
  fn.writeObject(PRODUCT_SAVED, choice);
};

const productSaved = (fn: LocalStorageFunctions): LocalProductSaved => ({
  getLocaStorageProductSaved: getLocaStorageProductSaved(fn),
  setLocaStorageProductSaved: setLocaStorageProductSaved(fn),
  delLocaStorageProductSaved: delLocaStorageProductSaved(fn)
});

export default productSaved;
