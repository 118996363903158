import type { LocalStorageFunctions, IntUserResponse, LocalSummaryOrder } from './types';
import { DEFAULT_ITEMS } from 'constants/summaryOrder';

const SUMMARY_ORDER: string = 'summary-order';
const USER: string = 'tigim-user';

const getUser = (fn: LocalStorageFunctions) => (): IntUserResponse | undefined =>
  fn.readObject(USER);

const getLocaStorageSummaryOrder = (fn: LocalStorageFunctions) => (): string[] => {
  const user = getUser(fn)();
  if (!user) return DEFAULT_ITEMS;
  const choice = fn.readObject(SUMMARY_ORDER);
  if (!choice?.[user.user.email]) return DEFAULT_ITEMS;
  return choice[user.user.email];
};

const setLocaStorageSummaryOrder = (fn: LocalStorageFunctions) => (data: string[]) => {
  const user = getUser(fn)();
  if (!user) return undefined;
  let choice = fn.readObject(SUMMARY_ORDER);
  if (!choice) {
    choice = {};
  }
  choice[user.user.email] = data;
  fn.writeObject(SUMMARY_ORDER, choice);
};

const delLocaStorageSummaryOrder = (fn: LocalStorageFunctions) => () => {
  const user = getUser(fn)();
  if (!user) return undefined;
  const choice = fn.readObject(SUMMARY_ORDER);
  if (!choice?.[user.user.email]) return undefined;
  delete choice[user.user.email];
  fn.writeObject(SUMMARY_ORDER, choice);
};

const summaryOrder = (fn: LocalStorageFunctions): LocalSummaryOrder => ({
  getLocaStorageSummaryOrder: getLocaStorageSummaryOrder(fn),
  setLocaStorageSummaryOrder: setLocaStorageSummaryOrder(fn),
  delLocaStorageSummaryOrder: delLocaStorageSummaryOrder(fn)
});

export default summaryOrder;
