import { createSlice } from '@reduxjs/toolkit';

interface State {
  signUp: boolean;
  name: string;
  email: string;
}

interface Action {
  payload: Payload;
}

interface Payload {
  name?: string;
  email?: string;
}

const initialState: State = {
  signUp: false,
  name: '',
  email: ''
};

export const slice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    open: (state: State) => {
      state.signUp = true;
    },
    close: (state: State) => {
      state.signUp = false;
    },
    openWithData: (state: State, action: Action) => {
      state.name = action.payload.name || '';
      state.email = action.payload.email || '';
      state.signUp = true;
    }
  }
});

export const { open, close, openWithData } = slice.actions;

export default slice.reducer;
