import type { LocalStorageFunctions, LocalLeftMenuState } from './types';

const LEFT_MENU_STATE: string = 'left-menu-state-closed';

const getLocaStorageLeftMenuState = (fn: LocalStorageFunctions) => (): boolean => !!fn.readObject(LEFT_MENU_STATE);
const setLocaStorageLeftMenuState= (fn: LocalStorageFunctions) => (isClosed: boolean) => fn.writeObject(LEFT_MENU_STATE, isClosed);

const leftMenuState = (fn: LocalStorageFunctions): LocalLeftMenuState => ({
  getLocaStorageLeftMenuState: getLocaStorageLeftMenuState(fn),
  setLocaStorageLeftMenuState: setLocaStorageLeftMenuState(fn)
});

export default leftMenuState;
