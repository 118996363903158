import type {
  Local,
  IntUser,
  IntUserResponse,
} from './types';
import type { ChoiceAsDefault as NewDocumentChoiceAsDefault } from 'pages/NewDocument/types';
import type { AppropriateTextSaved } from 'pages/ECommerceCreate/types';
import type { ComparableDocument, IntDocumentRow } from 'pages/MyDocuments/types';
import noop from 'functions/noop';

const defaultLocal: Local = {
  auth: {
    getUser: () => undefined,
    setUser: (_user: IntUserResponse) => {
      // This is intentional
    },
    delUser: noop,
    setUserUser: (_user: IntUser) => {
      // This is intentional
    },
    isPremium: () => true,
    isLoggedIn: () => true,
    hasSubscription: () => true,
    setHasSubscription: (_value: boolean) => {
      // This is intentional
    },
    getMaxChars: () => 0,
    getMaxPDFSize: () => 0,
    isAdmin: () => true,
    isPrepaid: () => true,
    isTrial: () => true,
    isPersonal: () => true,
    isBusiness: () => true,
    hasLicence: () => true,
    canShare: () => true,
    hasOnlyLicence: () => true,
    hasMyDocuments: () => true,
    removeOneCredit: () => {
      // This is intentional
    },
    updateCredits: (_credits: number) => {
      // This is intentional
    }
  },
  cookies: {
    getLocaStorageCookies: () => true,
    setLocaStorageCookies: noop
  },
  analysis: {
    getLastFullReportId: () => '',
    setLastFullReportId: (_id: string) => {
      // This is intentional
    },
    delLastFullReportId: noop
  },
  firstTime: {
    getLocaStorageFirstTime: () => true,
    setLocaStorageFirstTime: noop
  },
  tutorials: {
    setTutorialIsDone: (_tutorial: string) => {
      // This is intentional
    },
    getTutorials: () => [],
    clearTutorials: noop
  },
  touchPointsZoom: {
    setTouchPointZoom: (_rootId: string, _size: { width: number; height: number }) => {
      // This is intentional
    },
    getTouchPointZoom: (_rootId: string) => null
  },
  wordCloud: {
    getLocaStorageWordCloud: (_documentId: string) => [],
    setLocaStorageWordCloud: (_data: string, _documentId: string) => {
      // This is intentional
    },
    delLocaStorageWordCloud: (_documentId: string) => {
      // This is intentional
    }
  },
  cardStates: {
    getLocaStorageCardState: (_id: string, _defaultValue: boolean) => true,
    setLocaStorageCardState: (_id: string, _isOpen: boolean) => {
      // This is intentional
    }
  },
  preferences: {
    addOne: (_key: string, _value: any) => {
      // This is intentional
    },
    getOne: (_key: string) => {
      // This is intentional
    },
    SUGGESTION_FLAG: 'SUGGESTION_FLAG'
  },
  productSaved: {
    getLocaStorageProductSaved: () => [],
    setLocaStorageProductSaved: (_data: AppropriateTextSaved[]) => {
      // This is intentional
    },
    delLocaStorageProductSaved: noop
  },
  summaryOrder: {
    getLocaStorageSummaryOrder: () => [],
    setLocaStorageSummaryOrder: (_data: string[]) => {
      // This is intentional
    },
    delLocaStorageSummaryOrder: noop
  },
  leftMenuState: {
    getLocaStorageLeftMenuState: () => true,
    setLocaStorageLeftMenuState: (_isClosed: boolean) => {
      // This is intentional
    }
  },
  mobileWarning: {
    getLocaStorageMobileWarning: () => true,
    setLocaStorageMobileWarning: noop
  },
  choiceAsDefault: {
    getLocaStorageChoiceAsDefault: () => undefined,
    setLocaStorageChoiceAsDefault: (_data: NewDocumentChoiceAsDefault) => {
      // This is intentional
    },
    delLocaStorageChoiceAsDefault: noop
  },
  compareDocument: {
    getCompareDocument: () => undefined,
    setCompareDocument: (_data: ComparableDocument | undefined) => {
      // This is intentional
    },
    delCompareDocument: noop
  },
  uxFeaturesOrder: {
    getLocaStorageUxFeaturesOrder: () => [],
    setLocaStorageUxFeaturesOrder: (_data: string[]) => {
      // This is intentional
    },
    delLocaStorageUxFeaturesOrder: noop
  },
  selectedDocuments: {
    get: () => [],
    set: (_data: IntDocumentRow) => {
      // This is intentional
    },
    remove: noop
  },
  wordCloudSearched: {
    get: (_id: string, _defaultValue: boolean) => true,
    set: (_id: string, _isOpen: boolean) => {
      // This is intentional
    }
  },
  notEditableWarning: {
    getLocaStorageNotEditableWarning: () => true,
    setLocaStorageNotEditableWarning: noop
  },
  customerMetricsOrder: {
    getLocaStorageCustomerMetricsOrder: () => [],
    setLocaStorageCustomerMetricsOrder: (_data: string[]) => {
      // This is intentional
    },
    delLocaStorageCustomerMetricsOrder: noop
  }
};

export default defaultLocal;
