import type { ToastrEmitter } from "react-redux-toastr";

const errorMessage = (toastr: ToastrEmitter) => (error: any): void => {
  if (error?.response?.data?.detail) {
    if (error.response.data.detail === 'Null values not allowed') {
      return toastr.error('This element can\'t be deleted', '');
    }
    try {
      if (Array.isArray(error.response.data.detail)) {
        return toastr.error(error.response.data.detail[0].msg, '');
      } else {
        return toastr.error(error.response.data.detail, '');
      }
    } catch (e) {
      console.log(e)
    }
  }
  let message = error?.response?.data?.message || error?.message || error.toString();
  if (message === undefined) {
    message = 'Error unexpected';
  }
  toastr.error(message, '');
};

export default errorMessage;
