import styled from 'styled-components';

import { DEFAULT } from 'colors';

type DivProps = {
  $isOpen: boolean;
}

export const Div = styled.div<DivProps>`
  max-width: 220px;
  width: ${({ $isOpen }) => ($isOpen ? '100%' : '100px')};
  position: fixed;
  left: ${({ $isOpen }) => ($isOpen ? '34px' : '22px')};
  bottom: 115px;
  > div.cloud {
    height: 14px;
    margin-bottom: 13px;
    display: flex;
    > span {
      font-weight: bold;
      font-size: 12px;
      color: black;
      margin-left: 9px;
    }
    > img {
      margin-left: ${({ $isOpen }) => ($isOpen ? '0px' : '18px')};
    }
  }
  > div.bar {
    position: relative;
    width: ${({ $isOpen }) => ($isOpen ? '155px' : '40px')};
    background: #D1E5FF;
    border-radius: 2px;
    height: 3px;
    margin-bottom: 5px;
    margin-left: ${({ $isOpen }) => ($isOpen ? '0px' : '8px')};
    > div {
      position: absolute;
      left: 0;
      height: 3px;
      background: #7933FE;
      border-radius: 2px;
    }
  }
  > span {
    font-size: 12px;
    color: ${DEFAULT};
  }
`;