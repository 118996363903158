import React, { useState } from 'react';
import { useGoogleLogin, TokenResponse, CodeResponse } from '@react-oauth/google';
import {toastr} from 'react-redux-toastr';

import isOnLineGmail from 'functions/isOnLineGmail';
import InputPassword from 'components/atoms/InputPassword';
import useFocus from 'hooks/useFocus';
import validateEmail from 'functions/validateEmail';
import validatePassword from 'functions/validatePassword';
import gmailIcon from 'images/signUpLarge/gmail.svg';
import tigimIcon from 'images/tigim.svg';
import ModalForgot from './ModalForgot';
import Modal from './Modal';
import {
  ModalBodySignIn,
  InputModal,
  FormDiv,
  LoginDiv,
  ButtonSignIn,
  ButtonLogIn,
  SeparatorDiv,
  OptionsDiv,
  ButtonGmailLoginDiv
} from './elements';
import type { ModalSignInProps } from './types';

const ModalSignIn = ({onClose, onSend, onSendGmail, onForgot, defaultEmail}: ModalSignInProps) => {
  const [email, setEmail] = useState<string>(defaultEmail);
  const [password, setPassword] = useState<string>('');
  const [emailRef, setEmailFocus] = useFocus();
  const [passwordRef, setPasswordFocus] = useFocus();
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);

  const isValid = () =>
    validateEmail(email) && validatePassword(password);

  const handleSend = () => {
    if (!validateEmail(email)) return setEmailFocus();
    if (!validatePassword(password)) return setPasswordFocus();
    onSend({ email, password });
    onClose();
  };

  const onSuccess = (googleUser: TokenResponse | CodeResponse) => {
    if (isOnLineGmail(googleUser)) {
      onSendGmail(googleUser);
      onClose();
    }
  };

  const onError = (gmailError: Pick<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => {
    if (gmailError.error) {
      toastr.error('', gmailError.error);
    }
  };
  const signIn = useGoogleLogin({
    onSuccess,
    onError
  });
  const login = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    signIn();
  }
  const onForgotPassword = () => {
    setForgotPassword(true);
  }
  const handleForgot = (emailValue: string) => {
    setForgotPassword(false);
    return onForgot(emailValue);
  }
  const onReturn = () => {
    setForgotPassword(false);
  }
  const onKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };
  if (forgotPassword) {
    return (
      <ModalForgot
        onClose={onClose}
        onSend={handleForgot}
        onReturn={onReturn} />
    );
  }
  return (
    <Modal onClose={onClose}>
      <ModalBodySignIn onClick={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()}>
        <button
          className="close"
          type="button"
          aria-label="Close"
          onClick={onClose}>
          <span aria-hidden="true">×</span>
        </button>
        <img src={tigimIcon} alt="tigim" />
        <h5>Log into your account</h5>
        <FormDiv>
          <OptionsDiv>
            <ButtonGmailLoginDiv onClick={login}>
              <img src={gmailIcon} alt="gmail" />
              <span>Sign in with Google</span>
            </ButtonGmailLoginDiv>
          </OptionsDiv>
          <SeparatorDiv>
            <hr /><span>OR</span><hr />
          </SeparatorDiv>
          <InputModal
            ref={emailRef}
            id="email"
            name="email"
            placeholder="Email"
            className={validateEmail(email)? '': 'empty'}
            value={email}
            disabled={!!defaultEmail}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}/>
          <InputPassword
            reference={passwordRef}
            id="password"
            placeholder="Password"
            value={password}
            onKeyPress={onKeyPress}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}/>
          <ButtonSignIn
            className={isValid()? 'active': 'inactive'}
            type="button"
            aria-label="Sign in"
            onClick={handleSend}>
            LOGIN
          </ButtonSignIn>
          <LoginDiv>
            <ButtonLogIn onClick={onForgotPassword}>
              Forgot password?
            </ButtonLogIn>
          </LoginDiv>
        </FormDiv>
      </ModalBodySignIn>
    </Modal>
  );
};

export default ModalSignIn;
