import styled from 'styled-components';

import { DEFAULT, DEFAULT_LIGHT } from 'colors';

type AsideProps = {
  $active: boolean;
  $canOpen: boolean;
}

export const Aside = styled.aside<AsideProps>`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100vh;
  width: ${({ $canOpen }) => ($canOpen ? '245px' : '100px')};
  > div.menu {
    height: 100vh;
    background-color: white;
    width: ${({ $active }) => ($active ? '245px' : '100px')};
    display: flex;
    flex-direction: column;
    > div.menu {
      display: flex;
      flex-direction: column;
      > div.sub-menu-ecommerce {
        z-index: 2;
        max-width: 225px;
        background-color: white;
        div.sub-menu--body {
          display: none;
          > button:hover {
            background-color: #FBFBFB;
          }
        }
        span.sub-menu-title {
          color: ${DEFAULT_LIGHT};
        }
        img.actived {
          display: none;
        }
        img.inactived {
          display: block;
        }
        > div {
          > span {
            display: none;
          }
          > div {
            background: transparent;
            > img.actived {
              display: none;
            }
            > img.inactived {
              display: block;
            }
          }
          > button {
            display: flex;
            flex-direction: row;
            border: none;
            margin-left: 50px;
            max-width: 155px;
            align-items: center;
            height: 34px;
            margin: 0 0 0 16px;
            padding: 11px;
            cursor: pointer;
            background-color: transparent;
            img {
              width: 14px;
            }
            > span {
              display: none;
            }
          }
        }
      }
      > div.sub-menu-ecommerce.active {
        span.sub-menu-title {
          font-weight: 700;
          color: ${DEFAULT};
        }
        > div {
          > div {
            background: linear-gradient(134.05deg, #EB4076 4.44%, #7933FE 102.92%);
            > img.actived {
              display: block;
            }
            > img.inactived {
              display: none;
            }
          }
        }
      }
      > div.sub-menu-ecommerce.open {
        > div {
          > span {
            display: block;
          }
        }
      }
      > div.sub-menu-ecommerce:hover {
        box-shadow: 1px 4px 12px rgba(0,0,0,0.12);
        width: 235px;
        div.sub-menu--body {
          display: flex;
        }
        > div {
          > span {
            display: block;
          }
          > button {
            > div {
              background: transparent;
              img.actived {
                display: none;
              }
              img.inactived {
                display: block;
              }
            }
            > span {
              display: block;
            }
          }
          > button.active {
            > div {
              background: linear-gradient(134.05deg, #EB4076 4.44%, #7933FE 102.92%);
              img.actived {
                display: block;
              }
              img.inactived {
                display: none;
              }
            }
            > span {
              color: ${DEFAULT};
            }
          }
        }
      }
      > div.sub-menu-ecommerce:hover {
        width: 235px;
      }
    }
    > div.logo {
      display: flex;
      flex-direction: row;
      margin-bottom: 23px;
      height: 75px;
      cursor: pointer;
      > a {
        > svg {
          margin-top: ${({ $active }) => ($active ? '23px' : '28px')};
          margin-left: ${({ $active }) => ($active ? '29px' : '28px')};
        }
      }
    }
    > button.activator.disabled {
      opacity: 0;
    }
    > button.activator {
      position: relative;
      padding-top: 19px;
      padding-bottom: 19px;
      opacity: 0;
      border: none;
      background-color: transparent;
      width: ${({ $active }) => ($active ? '245px' : '100px')};
      min-width: ${({ $active }) => ($active ? '245px' : '100px')};
      cursor: pointer;
      padding-left: ${({ $active }) => ($active ? '187px' : '0px')};
      > img {
        width: 24px;
      }
    }
    > div.tm {
      z-index: 733;
    }
  }
  > div.menu:hover > button.activator {
    opacity: 1;
  }
  > div.menu:hover > button.activator.disabled {
    opacity: 0;
  }
`;

type MenuItemDivProps = {
  $active: boolean;
  $open: boolean;
  $highlight: boolean;
}

export const MenuItemDiv = styled.button<MenuItemDivProps>`
  border: none;
  display: flex;
  flex-direction: row;
  width: ${({ $open }) => $open? '206px': '56px'};
  align-items: center;
  height: 54px;
  margin: 0 0 0 20px;
  padding: 11px;
  cursor: pointer;
  background-color: ${({ $active, $highlight }) => $active || $highlight? 'white' : 'transparent'};
  box-shadow: ${({ $active }) => $active? '1px 4px 12px rgba(0, 0, 0, 0.12)' : 'none'};
  border-radius: 12px;
  z-index: ${({ $highlight }) => $highlight? 6 : 3};
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background: ${({ $active }) => $active? 'linear-gradient(134.05deg, #EB4076 4.44%, #7933FE 102.92%)': 'transparent'};
  }
  > span {
    margin-left: 8px;
    font-weight: ${({ $active }) => $active? '700': '400'};
    opacity: ${({ $active }) => $active? '1': '0.65'};
    font-size: 16px;
    line-height: 22px;
    color: ${DEFAULT};
  }
`;

export const ContactUsDiv = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  height: 54px;
  width: fit-content;
  cursor: pointer;
  position: fixed;
  left: 11px;
  bottom: 37px;
  align-items: center;
  > span {
    margin-left: 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${DEFAULT};
  }
`;
