import React, { useState } from 'react';

import { useMainContext } from 'routes/MainContextProvider';
import Header from 'components/organisms/HeaderUser';
import Footer from 'components/organisms/Footer';
import LeftMenu from 'components/organisms/LeftMenu';
import CookieBanner from 'components/organisms/CookieBanner';
import ModalSignUpLarge from 'components/organisms/ModalSignUpLarge';
import ModalSignIn from 'components/organisms/ModalSignIn';
import ModalContactUs from 'components/organisms/ModalContactUs';
import ModalMobileWarning from 'components/organisms/ModalMobileWarning';
import Loading from 'components/atoms/FullscreenLoaderSVG/Loading';
import { Div, ReactTooltipDiv } from './elements';
import { ErrorDispatcherProps } from './types';

const ErrorDispatcher = ({ children, page, leftMenuClick, canOpen = true}: ErrorDispatcherProps) => {
  const { local } = useMainContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenLeftMenu, setIsOpenLeftMenu] = useState<boolean>(!local.leftMenuState.getLocaStorageLeftMenuState());
  return (
    <>
    <Div className="App" onClick={() => setIsOpen(false)} $canOpen={canOpen}>
      <LeftMenu
        active={page}
        leftMenuClick={leftMenuClick}
        canOpen={canOpen} 
        isOpen={isOpenLeftMenu}
        setIsOpen={setIsOpenLeftMenu} />
      <Header isOpen={isOpen} setIsOpen={setIsOpen} zIndex="3" isOpenLeftMenu={isOpenLeftMenu} />
      {children}
      <ModalSignUpLarge />
      <ModalSignIn />
      <ModalContactUs />
      <ModalMobileWarning />
      <Footer />
      <CookieBanner />
      <ReactTooltipDiv id='tooltip-general' aria-haspopup='true' />
    </Div>
    <Loading />
    </>
  );
};

export default ErrorDispatcher;
