import React from 'react';

import useToggle from 'hooks/useToggle';
import eyeOpenedIcon from 'images/step2/modalSignUp/eyeOpened.svg';
import eyeClosedIcon from 'images/step2/modalSignUp/eyeClosed.svg';
import { Div } from './elements';
import { InputPasswordProps } from './types';

const InputPassword = ({ onChange, placeholder, onKeyPress, reference, id, value }: InputPasswordProps) => {
  const [show, toggle] = useToggle(false);
  return (
    <Div>
      <input
        ref={reference}
        id={id}
        name={id}
        type={show? 'text': 'password'}
        placeholder={placeholder}
        className={value.length > 2? '': 'empty'}
        value={value}
        onKeyPress={onKeyPress}
        onChange={onChange}/>
      {value && (
        <button onClick={toggle}>
          <img
            src={show? eyeOpenedIcon: eyeClosedIcon}
            alt={show? 'Hide': 'Show'} />
        </button>
      )}
    </Div>
  );
};

export default InputPassword;
