import { AxiosRequestHeaders } from 'axios';

import getLocal from 'services/getLocal';

const authHeader = (): AxiosRequestHeaders => {
  const user = getLocal(localStorage).auth.getUser();
  if (user && user.access_token) {
    return { Authorization: 'Bearer ' + user.access_token } as AxiosRequestHeaders;
  } else {
    return {} as AxiosRequestHeaders;
  }
};

export default authHeader;
