import type { WordCloudCircle } from 'functions/getCircles';
import type { LocalStorageFunctions, LocalWordCloud } from './types';

const WORD_CLOUD: string = 'word-cloud';

const getLocaStorageWordCloud = (fn: LocalStorageFunctions) => (documentId: string): WordCloudCircle[] | undefined => {
  const choice = fn.readObject(WORD_CLOUD);
  if (!choice?.[documentId]) return;
  return JSON.parse(choice[documentId]);
};

const setLocaStorageWordCloud = (fn: LocalStorageFunctions) => (data: string, documentId: string) => {
  let choice = fn.readObject(WORD_CLOUD);
  if (!choice) {
    choice = {};
  }
  choice[documentId] = data;
  fn.writeObject(WORD_CLOUD, choice);
};

const delLocaStorageWordCloud = (fn: LocalStorageFunctions) => (documentId: string) => {
  const choice = fn.readObject(WORD_CLOUD);
  if (!choice?.[documentId]) return;
  delete choice[documentId];
  fn.writeObject(WORD_CLOUD, choice);
};

const wordCloud = (fn: LocalStorageFunctions): LocalWordCloud => ({
  getLocaStorageWordCloud: getLocaStorageWordCloud(fn),
  setLocaStorageWordCloud: setLocaStorageWordCloud(fn),
  delLocaStorageWordCloud: delLocaStorageWordCloud(fn)
});

export default wordCloud;
