import React from 'react';

import emailIcon from 'images/components/ModalContactUs/email.svg';
import calendarIcon from 'images/components/ModalContactUs/calendar.svg';
import { ModalGeneric } from 'components/atoms/Modal';
import contact from 'functions/contact';
import { ModalBody, ModalBodyContactUs } from './elements';
import type { ModalContactUsProps, ActionButtonProps} from './types';

const ModalContactUs = ({onClose, subject}: ModalContactUsProps) => {
  const sendEmail = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onClose();
    contact(subject).email('mailto:niamh@tigim.co');
  };
  const bookCalendar = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onClose();
    contact(subject).push('https://calendly.com/niamh-tigim/account?month=2022-03');
  };
  return (
    <ModalGeneric onClose={onClose} ModalDiv={ModalBodyContactUs}>
      <ModalBody onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}>
        <button
          className="close"
          type="button"
          aria-label="Close"
          onClick={onClose}>
          <span aria-hidden="true">×</span>
        </button>
        <h5>Contact Us</h5>
        <div>
          <ActionButton onClick={sendEmail} icon={emailIcon} title="Email Us" />
          <ActionButton onClick={bookCalendar} icon={calendarIcon} title="Book a Call" />
        </div>
      </ModalBody>
    </ModalGeneric>
  );
};

export default ModalContactUs;

const ActionButton = ({icon, title, onClick}: ActionButtonProps) => (
  <div>
    <button onClick={onClick}>
      <img src={icon} alt={title} />
    </button>
    <span>{title}</span>
  </div>
);
