import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import ErrorBoundary from 'ErrorBoundary';
import getLocal from 'services/getLocal';
import FullscreenLoaderSVG from 'components/atoms/FullscreenLoaderSVG';
import MainContextProviderProduction from './MainContextProviderProduction';

const AccountSettings = lazy(() => import('pages/AccountSettings'));
const Admin = lazy(() => import('pages/Admin'));
const CookiePolicy = lazy(() => import('pages/CookiePolicy'));
const FAQ = lazy(() => import('pages/FAQ'));
const Glossary = lazy(() => import('pages/Glossary'));
const HowTo = lazy(() => import('pages/HowTo'));
const NotFound = lazy(() => import('pages/NotFound'));
const Payments = lazy(() => import('pages/Payments'));
const BillingOwner = lazy(() => import('pages/Payments/BillingOwner'));
const Invoices = lazy(() => import('pages/Payments/Invoices'));
const Licences = lazy(() => import('pages/Payments/Licences'));
const PaymentInfo = lazy(() => import('pages/Payments/PaymentInfo'));
const PricingPrice = lazy(() => import('pages/Payments/PricingPrice'));
const Privacy = lazy(() => import('pages/Privacy'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));
const Terms = lazy(() => import('pages/Terms'));
const ValidateEmail = lazy(() => import('pages/ValidateEmail'));
const Home = lazy(() => import('pages/Home'));
const Analysis = lazy(() => import('pages/Analysis'));
const NewDocument = lazy(() => import('pages/NewDocument'));
const MyDocuments = lazy(() => import('pages/MyDocuments'));
const WordExplorer = lazy(() => import('pages/WordExplorer'));
const WordExplorerPublic = lazy(() => import('pages/WordExplorerPublic'));
const Customize = lazy(() => import('pages/Customize'));
const ReadabilityPublic = lazy(() => import('pages/ReadabilityPublic'));
const PublicBrainPain = lazy(() => import('pages/PublicBrainPain'));
const PublicSentimentAnalysis = lazy(() => import('pages/PublicSentimentAnalysis'));
const Comparison = lazy(() => import('pages/Comparison'));
const Ux = lazy(() => import('pages/Ux'));
const ECommerce = lazy(() => import('pages/ECommerce'));
const NewECommerce = lazy(() => import('pages/NewECommerce'));
const ECommerceProduct = lazy(() => import('pages/ECommerceProduct'));
const MyECommerceDocuments = lazy(() => import('pages/MyECommerceDocuments'));
const ECommerceCreate = lazy(() => import('pages/ECommerceCreate'));

type Meta = {
  auth?: boolean;
  notAuth?: boolean;
  admin?: boolean;
  notAdmin?: boolean;
  licenced?: boolean;
  payments?: boolean;
}

const requireLogin = (meta: Meta): string => {
  const user = getLocal(localStorage).auth.getUser();
  const isLoggedIn = user?.user?.email;
  if (meta.auth) {
    if (!isLoggedIn) {
      return '/analysis';
    }
  } else if (meta.notAuth) {
    if (isLoggedIn) {
      return '/';
    }
  }
  return '';
};

const requireAdmin = (meta: Meta): string => {
  const isAdmin = getLocal(localStorage).auth.isAdmin();
  if (meta.admin) {
    if (!isAdmin) {
      return '/analysis';
    }
  } else if (meta.notAdmin) {
    if (isAdmin) {
      return '/admin/users-administration';
    }
  }
  return '';
};

const requireLicenced = (meta: Meta): string => {
  if (meta.licenced) {
    const hasLicence = getLocal(localStorage).auth.hasLicence();
    if (!hasLicence) {
      return '/account';
    }
  }
  return '';
}

const requirePayments = (meta: Meta): string => {
  if (meta.payments) {
    const isPrepaid = getLocal(localStorage).auth.isPrepaid();
    const hasOnlyLicence = getLocal(localStorage).auth.hasOnlyLicence();
    if (isPrepaid || hasOnlyLicence) {
      return '/analysis';
    }
  }
  return '';
}

const Loader = () => (
  <MainContextProviderProduction componentName="loading">
    <FullscreenLoaderSVG text="Loading..." show={true}/>
  </MainContextProviderProduction>
);

const TigimRoutes = () => (
  <Suspense fallback={<Loader />}>
    <Routes>
      <Route path="/account" element={<Protector name="AccountSettings" elem={AccountSettings} meta={{ auth: true, notAdmin: true }} />} />
      <Route path="/payments/pricing-plan" element={<Protector name="PricingPrice" elem={PricingPrice} meta={{ auth: true, notAdmin: true, payments: true }} />} />
      <Route path="/payments/licences" element={<Protector name="Licences" elem={Licences} meta={{ auth: true, notAdmin: true, payments: true }} />} />
      <Route path="/payments/billing" element={<Protector name="BillingOwner" elem={BillingOwner} meta={{ auth: true, notAdmin: true, payments: true }} />} />
      <Route path="/payments/info" element={<Protector name="PaymentInfo" elem={PaymentInfo} meta={{ auth: true, notAdmin: true, payments: true }} />} />
      <Route path="/payments/invoices" element={<Protector name="Invoices" elem={Invoices} meta={{ auth: true, notAdmin: true, payments: true }} />} />
      <Route path="/payments" element={<Protector name="Payments" elem={Payments} meta={{ auth: true, notAdmin: true, payments: true }} />} />
      <Route path="/documents/:owner/:folder/:page" element={<Protector name="MyDocumentsFolder" elem={MyDocuments} meta={{ auth: true, notAdmin: true }} />} />
      <Route path="/documents/:owner/:page" element={<Protector name="MyDocuments" elem={MyDocuments} meta={{ auth: true, notAdmin: true }} />} />
      <Route path="/how-to/:page" element={<Protector name="HowTo" elem={HowTo} meta={{ auth: false, notAdmin: true }} />} />
      <Route path="/how-to" element={<Protector name="HowTo" elem={HowTo} meta={{ auth: false, notAdmin: true }} />} />
      <Route path="/glossary/:char/:page" element={<Protector name="Glossary" elem={Glossary} meta={{ auth: false }} />} />
      <Route path="/glossary/:char/" element={<Protector name="Glossary" elem={Glossary} meta={{ auth: false }} />} />
      <Route path="/faq/" element={<Protector name="FAQ" elem={FAQ} meta={{ auth: false }} />} />
      <Route path="/admin/:page/:id" element={<Protector name="Admin" elem={Admin} meta={{ auth: true, admin: true }} />} />
      <Route path="/admin/:page" element={<Protector name="Admin" elem={Admin} meta={{ auth: true, admin: true }} />} />
      <Route path="/email_confirmation/:token" element={<Protector name="ValidateEmail" elem={ValidateEmail} />} />
      <Route path="/reset-password/:token" element={<Protector name="ResetPassword" elem={ResetPassword} />} />
      <Route path="/privacy" element={<Protector name="Privacy" elem={Privacy} />} />
      <Route path="/terms" element={<Protector name="Terms" elem={Terms} />} />
      <Route path="/cookie-policy" element={<Protector name="CookiePolicy" elem={CookiePolicy} />} />
      <Route path="/word-explorer" element={<Protector name="WordExplorer" elem={WordExplorer} />} />
      <Route path="/word-explorer-public" element={<Protector name="WordExplorerPublic" elem={WordExplorerPublic} />} />
      <Route path="/readability-checker" element={<Protector name="ReadabilityPublic" elem={ReadabilityPublic} />} />
      <Route path="/sentiment-analysis" element={<Protector name="PublicSentimentAnalysis" elem={PublicSentimentAnalysis} />} />
      <Route path="/brain-pain" element={<Protector name="PublicBrainPain" elem={PublicBrainPain} />} />
      <Route path="/customize/:tab" element={<Protector name="Customize" elem={Customize} meta={{ auth: true, notAdmin: true }} />} />
      <Route path="/analysis/:id/:tab/:subtab" element={<Protector name="Analysis" elem={Analysis} />} />
      <Route path="/analysis/:id/:tab" element={<Protector name="Analysis" elem={Analysis} />} />
      <Route path="/analysis" element={<Protector name="NewDocument" elem={NewDocument} meta={{ notAdmin: true, licenced: true }} />} />
      <Route path="/login/:email" element={<Protector name="Login" elem={NewDocument} meta={{ auth: false }} />} />
      <Route path="/e-commerce/product" element={<Protector name="ECommerceCreate" elem={ECommerceCreate} />} />
      <Route path="/comparison/:doc1/:doc2" element={<Protector name="Comparison" elem={Comparison} />} />
      <Route path="/ux/:id" element={<Protector name="Ux" elem={Ux} />} />
      <Route path="/e-commerce/:doc/:region/:id/:tab" element={<Protector name="ECommerceProduct" elem={ECommerceProduct} />} />
      <Route path="/e-commerce/:doc/:region/:page" element={<Protector name="ECommerce" elem={ECommerce} />} />
      <Route path="/new-e-commerce" element={<Protector name="NewECommerce" elem={NewECommerce} />} />
      <Route path="/my-e-commerce-documents" element={<Protector name="MyECommerceDocuments" elem={MyECommerceDocuments} />} />
      <Route path="/" element={<Protector name="Home" elem={Home} meta={{ auth: true, notAdmin: true }} />} />
      <Route path="*" element={<Protector name="NotFound" elem={NotFound} />} />
    </Routes>
  </Suspense>
);

export default TigimRoutes;

const guards = [requireLogin, requireAdmin, requireLicenced, requirePayments];

interface ProtectorProps {
  name: string;
  elem: React.LazyExoticComponent<() => React.JSX.Element | null>;
  meta?: Meta;
}

const Protector = ({ meta, elem, name }: ProtectorProps) => {
  const metaPro = meta ?? {};
  let res: string = '';
  for (let guard of guards) {
    res = guard(metaPro);
    if (res.length > 0) {
      break;
    }
  }
  if (res.length > 0) {
    window.location.replace(res)
    return null;
  }
  const Comp = elem;
  return (
    <ErrorBoundary componentName={name}>
      <MainContextProviderProduction componentName={name}>
        <Comp />
      </MainContextProviderProduction>
    </ErrorBoundary>
  );
}
