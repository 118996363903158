import React from 'react';

import { useMainContext } from 'routes/MainContextProvider';
import { MainDiv, BackDropDiv } from 'components/atoms/Modal/elements';
import noop from 'functions/noop';

interface TutorialCoversProps {
  steps: number[];
  doNext?: boolean;
  onDoubleClick?: () => void;
}

const getTutorialCovers = (name: string) => ({ steps, doNext=true, onDoubleClick=noop }: TutorialCoversProps) => {
  const { tutorial, tutorialStep, nextStep } = useMainContext();
  if (tutorial !== name || !steps.includes(tutorialStep)) return null;
  const handleClick = () => {
    if (doNext) nextStep();
  };
  return (
    <MainDiv>
      <BackDropDiv onClick={handleClick} onDoubleClick={onDoubleClick} />
    </MainDiv>
  );
};

export default getTutorialCovers;
