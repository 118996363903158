import React from 'react';

import AppIcon from 'components/atoms/AppIcon';
import {
  ModalBody,
  ModalBodyMobileWarning,
  ThankDiv,
  FooterDiv
} from './elements';
import { ModalGeneric } from 'components/atoms/Modal';
import { ModalMobileWarningProps } from './types';

const ModalMobileWarning = ({onClose}: ModalMobileWarningProps) => (
  <ModalGeneric onClose={onClose} ModalDiv={ModalBodyMobileWarning}>
    <ModalBody onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}>
      <button
        className="close"
        type="button"
        aria-label="Close"
        onClick={onClose}>
        <span aria-hidden="true">×</span>
      </button>
      <AppIcon />
      <ThankDiv>
        <p className="title">Thank you for trying<br /><span>Tigim Beta</span></p>
        <p>Currently, our program works only<br />via desktop devices.</p>
      </ThankDiv>
      <FooterDiv>
        <p>For more info, please visit:</p>
        <a href="https://tigim.co">tigim.co</a>
      </FooterDiv>
    </ModalBody>
  </ModalGeneric>
);

export default ModalMobileWarning;
