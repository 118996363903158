import React from 'react';

import cardPlusIcon from 'images/eCommerce/cardPlusDefault.svg';
import cardPlusWhiteIcon from 'images/eCommerce/cardPlusWhite.svg';
import eCommerceIcon from 'images/leftMenu/eCommerce.svg';
import eCommerceBoxIcon from 'images/leftMenu/eCommerceBox.svg';
import eCommerceBoxWhiteIcon from 'images/leftMenu/eCommerceBoxWhite.svg';
import eCommerceEditIcon from 'images/leftMenu/eCommerceEdit.svg';
import eCommerceEditWhiteIcon from 'images/leftMenu/eCommerceEditWhite.svg';
import eCommerceWhiteIcon from 'images/leftMenu/eCommerceWhite.svg';
import { useMainContext } from 'routes/MainContextProvider';
import { open as setOpenContactLogin } from 'store/reducers/contactLogin';

type Props = {
  active: string;
  isOpen: boolean;
}

const SubMenu = ({active, isOpen}: Props) => {
  const { history, dispatch, isLoggedIn, local } = useMainContext();
  if (process.env.REACT_APP_ECOMMERCE !== 'true') return null;
  const onClickNewECommerce = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!isLoggedIn) {
      local.selectedDocuments.remove();
      local.compareDocument.delCompareDocument();
      return dispatch(setOpenContactLogin(''));
    }
    history('/new-e-commerce');
  };

  const onClickMyECommerceDocuments = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!isLoggedIn) {
      local.selectedDocuments.remove();
      local.compareDocument.delCompareDocument();
      return dispatch(setOpenContactLogin(''));
    }
    history('/my-e-commerce-documents');
  };

  const onClickECommerceCreate = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!isLoggedIn) {
      local.selectedDocuments.remove();
      local.compareDocument.delCompareDocument();
      return dispatch(setOpenContactLogin(''));
    }
    history('/e-commerce/product');
  };

  const isActive = ['MyECommerceDocuments', 'NewECommerce', 'ECommerce', 'ECommerceProduct', 'ECommerceCreate'].includes(active);
  return (
    <div className={`sub-menu-ecommerce br-12 ml-12 ${isActive? 'active': ''} ${isOpen? 'open': 'close'}`}>
      <div className='flex-row align-items-center'>
        <div className='ml-18 mt-8 pd-4 br-8'>
          <img src={eCommerceWhiteIcon} alt='e-commerce' className='actived' />
          <img src={eCommerceIcon} alt='e-commerce' className='inactived' />
        </div>
        <span className='ml-12 mt-8 sub-menu-title'>Store</span>
      </div>
      <div className='flex-col sub-menu--body'>
        <button
          className={active === 'NewECommerce'? 'active': ''}
          onClick={onClickNewECommerce}
          aria-label="Left menu New Product"
          id='NewECommerce'>
          <div className="br-8 pd-4">
            <img src={cardPlusWhiteIcon} alt='New Product' className='actived' />
            <img src={cardPlusIcon} alt='New Product' className='inactived' />
          </div>
          <span className='ml-16 f-16 fw-400 color-default-light'>New Product</span>
        </button>
        <button
          className={active === 'MyECommerceDocuments' || active === 'ECommerce' || active === 'ECommerceProduct'? 'active': ''}
          onClick={onClickMyECommerceDocuments}
          aria-label="Left menu Product Sets"
          id='MyECommerceDocuments'>
          <div className="br-8 pd-4">
            <img src={eCommerceBoxWhiteIcon} alt='Product Sets' className='actived' />
            <img src={eCommerceBoxIcon} alt='Product Sets' className='inactived' />
          </div>
          <span className='ml-16 f-16 fw-400 color-default-light'>Product Sets</span>
        </button>
        <button
          className={active === 'ECommerceCreate'? 'active': ''}
          onClick={onClickECommerceCreate}
          aria-label="Left menu Create"
          id='CreateECommerce'>
          <div className="br-8 pd-4">
            <img src={eCommerceEditWhiteIcon} alt='Create' className='actived' />
            <img src={eCommerceEditIcon} alt='Create' className='inactived' />
          </div>
          <span className='ml-16 f-16 fw-400 color-default-light'>Create</span>
        </button>
      </div>
    </div>
  );
};

export default SubMenu;
