import type { LocalMobileWarning, LocalStorageFunctions } from './types';

const MOBILE_WARNING: string = 'mobile-warning';

const getLocaStorageMobileWarning = (fn: LocalStorageFunctions) => (): boolean => !!fn.readObject(MOBILE_WARNING);
const setLocaStorageMobileWarning = (fn: LocalStorageFunctions) => () => fn.writeObject(MOBILE_WARNING, true);

const mobileWarning = (fn: LocalStorageFunctions): LocalMobileWarning => ({
  getLocaStorageMobileWarning: getLocaStorageMobileWarning(fn),
  setLocaStorageMobileWarning: setLocaStorageMobileWarning(fn)
});

export default mobileWarning;
