import type { LocalCookies, LocalStorageFunctions } from './types';

const COOKIES: string = 'cookies';

const getLocaStorageCookies = (fn: LocalStorageFunctions) => (): boolean => fn.readObject(COOKIES);
const setLocaStorageCookies = (fn: LocalStorageFunctions) => () => fn.writeObject(COOKIES, true);

const cookies = (fn: LocalStorageFunctions): LocalCookies => ({
  getLocaStorageCookies: getLocaStorageCookies(fn),
  setLocaStorageCookies: setLocaStorageCookies(fn)
});

export default cookies;
