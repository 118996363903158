import type { LocalStorageFunctions, LocalFirstTime } from './types';

const FIRST_TIME: string = 'first-time-flag';

const getLocaStorageFirstTime = (fn: LocalStorageFunctions) => (): boolean => !!fn.readObject(FIRST_TIME);
const setLocaStorageFirstTime = (fn: LocalStorageFunctions) => () => fn.writeObject(FIRST_TIME, true);

const firstTime = (fn: LocalStorageFunctions): LocalFirstTime => ({
  getLocaStorageFirstTime: getLocaStorageFirstTime(fn),
  setLocaStorageFirstTime: setLocaStorageFirstTime(fn)
});

export default firstTime;
